.navbar-brand {
    .logo-container {
        .logo {
            img {
                height: 22px !important;
                width: auto !important;
            }
        }
    }
}
