#copyrightForForm {
    a {
        .form-logo {
            color: $logo-color;
            img {
                vertical-align: text-bottom;
                height: auto;
            }
        }
    }
}
