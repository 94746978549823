@import 'variables';

.org-key-metrics {
    #org-key-metrics-counts {
        @mixin style-points-card($color) {
            .points-card .card-body {
                background-color: rgba($color, 0.1) !important;
                .card-title {
                    background-color: $color !important;
                }
            }
        }
        & > .card-enquiries {
            @include style-points-card(darken($stage-interest, 20%));
        }
        & > .card-applicant {
            @include style-points-card($stage-applicant);
        }
        & > .card-enrolled {
            @include style-points-card($stage-enrolled);
        }
        & > .card-declined {
            @include style-points-card(darken($stage-declined, 20%));
        }
    }

    .table-responsive {
        table {
            tr {
                td {
                    &.enquiries-count {
                        background-color: rgba(darken($stage-interest, 20%), 0.1) !important;
                    }
                    &.applicants-count {
                        background-color: rgba($stage-applicant, 0.1) !important;
                    }
                    &.enroled-count {
                        background-color: rgba($stage-enrolled, 0.1) !important;
                    }
                    &.declined-count {
                        background-color: rgba(darken($stage-declined, 20%), 0.1) !important;
                    }
                }
            }
        }
    }
}
