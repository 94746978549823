$btn-border-width: 2px !default;

$btn-link-margin-spacer-x: 3px !default;
$btn-link-margin-spacer-y: 4px !default;
$btn-link-padding-spacer-x: 3px !default;
$btn-link-padding-spacer-y: 6px !default;
$btn-link-bg: $white !default;

$btn-secondary-color: $brand-primary;
$btn-secondary-bg: $white;
$btn-secondary-border-color: $brand-primary;
