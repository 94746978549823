$french-blue: #5358b0;
$perrywinkle: #888de4;
$bluegrey: #93b8c3;
$faded-blue: #5ea8be;
$aqua: #15d4d9;
$mint: #11d5bf;
$cerulean: #0688e0;
$navy: #031632;
$marine: #072044;
$marine-lighter: #5e6e85;
$marine-light: #394d69;
$duck-egg-blue: #d4e5f9;
$duck-egg-blue-lighter: #eef5fd;
$pale-grey: #f4f5f9;
$greenish: #3fa267;
$pastel-red: #ec5a5a;
$pumpkin: #e57800;
$link-blue: #368bd2;
$greyblue: #7e96c0;
$dark-cyan: #0e7395;
$teal-blue: #0098aa;
$primary-teal: #009eaa;
$fun-blue: #1a4fa3;
$dusk-blue: #233e77;

// Charts Color
$spindle: #c2d7f0;
$portage: #8c6cf2;
$cornflower-blue: #68b3f2;
$anakiwa: #7bd9ff;
$turquoise: #30d8ad;
$spary: #7af2bc;
$golden-sand: #f4e475;
$koromiko: #fcb65e;
$tan-hide: #f68e4b;
$regent-st-blue: #9cbfe4;
$lavender: #dc7ee5;

// Event Summary Table Colors
$stage-interest: $regent-st-blue !default;
$stage-applicant: $dusk-blue !default;
$stage-enrolled: $primary-teal !default;
$stage-declined: $spindle !default;
